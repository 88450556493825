import React, { useState } from "react";
import "../styles/Gallery.css";
import Nav from "../otherJS/Nav.js";
import Footer from "../otherJS/Footer";
import Igby from "../assets/Igby.png";
import Bufo from "../assets/Bufo.png";
import Kitten from "../assets/Kitten.JPG";
import Kaa from "../assets/Kah.png";
import WaffleKirby from "../assets/Waffle-Kirbs.png";
import Rookie from "../assets/Rookie.png";

export default function Gallery() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalContent, setModalContent] = useState({
    header: "",
    text: "",
  });

  const openModal = (image, header, text) => {
    setSelectedImage(image);
    setModalContent({ header, text });
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="Gallery">
      <header className="Home-header">
        <Nav />
      </header>
      <center>
        <div className="site-content content d-flex flex-column min-vh-100">
          <div className="container-fluid">
            <h1>Gallery</h1>
            <p>
              Here to be admired are just a handful of the cats, dogs, and
              critters I have had the pleasure of working with.
              <br class="desktop-only-br" /> I hope you enjoy getting to know
              them as much as I have.
            </p>
            <div className="row">
              <div className="col-12 col-md-4 mt-3">
                <div className="square-image-container mt-2">
                  <img
                    className="square-image"
                    src={Igby}
                    alt="A beautiful collie dog"
                    onClick={() =>
                      openModal(
                        Igby,
                        "Igby",
                        "Igby is a young rough collie. She is full of life and is extra sassy."
                      )
                    }
                  />

                  {selectedImage && (
                    <div
                      className="modal fade show"
                      tabIndex="-1"
                      role="dialog"
                      style={{ display: "block" }}
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title w-100"
                              id="exampleModalLabel"
                            >
                              {modalContent.header}
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              onClick={closeModal}
                              aria-label="Close"
                            >
                              <span aria-hidden="true"></span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <img
                              style={{ maxWidth: "60%" }}
                              src={selectedImage}
                              alt="Selected"
                            />
                            <p>{modalContent.text}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-4 mt-3">
                <div className="square-image-container mt-2">
                  <img
                    className="square-image"
                    src={Bufo}
                    alt="A cute frog"
                    onClick={() =>
                      openModal(
                        Bufo,
                        "Bufo",
                        "Bufo is a pacman frog. She is ready and willing to eat all the things!"
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-12 col-md-4 mt-3">
                <div className="square-image-container mt-2">
                  <img
                    className="square-image"
                    src={Kitten}
                    alt="A beautiful white cat with black spots, sunbathing"
                    onClick={() =>
                      openModal(
                        Kitten,
                        "Kitten",
                        "Kitten is a grumpy old lady. She doesn't like many other animals, but she loves her humans...and seaweed snacks!"
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 mt-3">
                <div className="square-image-container mt-2">
                  <img
                    className="square-image"
                    src={Kaa}
                    alt="A regal snake"
                    onClick={() =>
                      openModal(
                        Kaa,
                        "Kaa",
                        "Kaa is a ball python. He loves to be draped around shoulders and chowing down on mice."
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-12 col-md-4 mt-3">
                <div className="square-image-container mt-2">
                  <img
                    className="square-image"
                    src={WaffleKirby}
                    alt="A beautiful labrador dog"
                    onClick={() =>
                      openModal(
                        WaffleKirby,
                        "Waffle & Kirby",
                        "Kirby the Cat and Waffle the Dog are best buddies. They chase each other around the living room and snuggle in sunbeams together. Kirby also loves her cat brother, Nibbles, and Waffle loves snackos."
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-12 col-md-4 mt-3">
                <div className="square-image-container mt-2">
                  <img
                    className="square-image"
                    src={Rookie}
                    alt="A happy black lab"
                    onClick={() =>
                      openModal(
                        Rookie,
                        "Rookie",
                        "Rookie is the goodest boy. He used to be labeled aggressive, but now he wouldn't harm a fly, thanks to Lyric. When playing with a ball, he will toss it back to you!"
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </center>
      <footer className="footer-container">
        <Footer />
      </footer>
    </div>
  );
}
