import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "../styles/ContactForm.css";

export default function ContactForm() {
  const form = useRef();
  const [isSent, setIsSent] = useState(false);
  const [isError, setIsError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_6rmoqpa",
        "template_ldgh2mf",
        form.current,
        "kBq93U8tObvE4vj15"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsSent(true);
          setIsError(false);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
          setIsSent(false);
          setIsError(true);
        }
      );
  };

  return (
    <div className="ContactForm">
      <div className="container">
        <form ref={form} onSubmit={sendEmail}>
          <div class="form-group">
            <label for="inputName" style={{ visibility: "hidden" }}>
              Name
            </label>
            <input
              className="form-control"
              id="inputName"
              type="text"
              required
              placeholder="Name"
              name="user_name"
            />
          </div>
          <div class="form-group">
            <label style={{ visibility: "hidden" }}>Email</label>
            <input
              className="form-control"
              type="email"
              aria-describedby="emailHelp"
              required
              placeholder="Email"
              name="user_email"
            />
          </div>
          <div class="form-group">
            <label style={{ visibility: "hidden" }}>Message</label>
            <textarea
              className="form-control"
              required
              placeholder="Message"
              name="message"
            />
          </div>

          <button type="submit" class="btn btn-primary">
            Submit
          </button>
        </form>
        {isSent && !isError && (
          <div style={{ color: "green" }}>Email sent successfully!</div>
        )}
        {isError && (
          <div style={{ color: "red" }}>
            There was an error sending your email. Please try again.
          </div>
        )}
      </div>
    </div>
  );
}
