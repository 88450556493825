import React from "react";
import "../styles/Carousel.css";
import { Link } from "react-router-dom";
//import Logo from "../assets/temp_logo1.PNG";
import Waffle from "../assets/Waffle.png";
import Blu from "../assets/Blu.png";
import IgbyRookie from "../assets/Igby-Rookie.png";

export default function Carousel() {
  return (
    <div>
      <div
        id="carouselExampleAutoplaying"
        class="carousel slide carousel-fade"
        data-bs-ride="carousel"
        data-bs-pause="false"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div className="overlay"></div>
            <div className="hero">
              <hgroup>
                <h2>Corn Pups</h2>
                <h4>Join the Corn Pup Pack</h4>
              </hgroup>
              <Link
                className="btn btn-hero btn-lg"
                to="/services"
                role="button"
              >
                Services
              </Link>
              <Link
                className="btn btn-hero btn-lg d-none d-md-inline-block"
                to="/contact"
                role="button"
              >
                Contact
              </Link>
            </div>
            <img src={Waffle} class="d-block w-100" alt="a dog in the snow" />
          </div>

          <div class="carousel-item">
            <div className="overlay"></div>
            <div className="hero">
              <hgroup>
                <h2>Corn Pups</h2>
                <h4>Join the Corn Pup Pack</h4>
              </hgroup>
              <Link
                className="btn btn-hero btn-lg"
                to="/services"
                role="button"
              >
                Services
              </Link>
              <Link
                className="btn btn-hero btn-lg d-none d-md-inline-block"
                to="/contact"
                role="button"
              >
                Contact
              </Link>
            </div>
            <img src={Blu} class="d-block w-100" alt="a collie and a lab" />
          </div>
          <div class="carousel-item">
            <div className="overlay"></div>
            <div className="hero">
              <hgroup>
                <h2>Corn Pups</h2>
                <h4>Join the Corn Pup Pack</h4>
              </hgroup>
              <Link
                className="btn btn-hero btn-lg"
                to="/services"
                role="button"
              >
                Services
              </Link>
              <Link
                className="btn btn-hero btn-lg d-none d-md-inline-block"
                to="/contact"
                role="button"
              >
                Contact
              </Link>
            </div>
            <img src={IgbyRookie} class="d-block w-100" alt="small white dog" />
          </div>
        </div>
      </div>
      {/*
      <img
        className="logo-overlay"
        src={Logo}
        alt="Logo saying 'Corn Pups: Keeping Pets Happy Since 2017'"
      />{" "}
      */}
    </div>
  );
}
