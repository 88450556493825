import React from "react";
import Nav from "../otherJS/Nav.js";
import { Link } from "react-router-dom";
import "../styles/About.css";
import LyricCharlie from "../assets/Lyric_Charlie.png";
import Footer from "../otherJS/Footer";

export default function About() {
  return (
    <div className="About">
      <header className="Home-header">
        <Nav />
      </header>
      <div className="content">
        <div className="row about-main">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 pt-4 pt-lg-0">
            <div className="about-lyric" style={{ display: "grid" }}>
              <div
                className="row"
                id="swip-swap-a"
                style={({ margin: "30px" }, { display: "grid" })}
              >
                <div className="col-2"></div>
                <div className="col-10" style={{ margin: "0 auto" }}>
                  <h1>About Lyric</h1>
                  <p>
                    Hi! My Name is Lyric, and I love working with animals. I
                    started my career in the pet services industry while nursing
                    a relative’s dog back to health when she hurt her hip. I
                    realized I had an affinity for working with animals and
                    threw myself into it full time. I have over 7 years of
                    experience in behavioral training, dog and cat walking, pet
                    sitting, and boarding. I maintain an approach of positive
                    reinforcement and patience with all animals. I have been
                    given the opportunity to work with a wide array of animals,
                    from mammals and reptiles to insects and fish. I love what I
                    do, and I look forward to providing you and your pet with
                    excellent service and care!
                  </p>
                </div>
                <div className="col-2"></div>
              </div>
              <div
                className="row"
                id="swip-swap-b"
                style={({ margin: "30px" }, { display: "grid" })}
              >
                <div className="col-2"></div>
                <div className="col-8">
                  <img
                    className="col-12"
                    alt="Lyric Bonitto"
                    src={LyricCharlie}
                  ></img>
                </div>
                <div className="col-2"></div>
              </div>
              <div className="row" id="swip-swap-c">
                <div className="col">
                  <Link
                    className="btn btn-hero btn-lg"
                    to="/services"
                    role="button"
                  >
                    Services
                  </Link>
                  <Link
                    className="btn btn-hero btn-lg d-none d-md-inline-block"
                    to="/contact"
                    role="button"
                  >
                    Contact
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
      <footer className="footer-container">
        <Footer />
      </footer>
    </div>
  );
}
