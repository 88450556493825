import React from "react";
import "../styles/Nav.css";
import { Link } from "react-router-dom";
import Logo from "../assets/temp_logo2.PNG";

export default function Nav() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img className="logo" src={Logo} alt="Corn Pups logo"></img>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item ml-auto px-2">
                <Link to="/" className="nav-link active" aria-current="page">
                  Home
                </Link>
              </li>
              <li className="nav-item ml-auto px-2">
                <Link to="/about" className="nav-link">
                  About
                </Link>
              </li>
              <li className="nav-item ml-auto px-2">
                <Link to="/services" className="nav-link">
                  Services
                </Link>
              </li>
              <li className="nav-item ml-auto px-2">
                <Link to="/testimonials" className="nav-link">
                  Testimonials
                </Link>
              </li>
              <li className="nav-item ml-auto px-2">
                <Link to="/gallery" className="nav-link">
                  Gallery
                </Link>
              </li>
              <li className="nav-item ml-auto px-2">
                <Link to="/contact" className="nav-link">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
