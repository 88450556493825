import React from "react";
import "../styles/Footer.css";
import TikTok from "../assets/icon_tiktok.png";
import Instagram from "../assets/icon_instagram.png";
import Facebook from "../assets/icon_facebook.png";
import Email from "../assets/icon_email.png";

export default function Footer() {
  return (
    <div className="footer">
      <a
        href="https://www.tiktok.com/@cornpupswa?is_from_webapp=1&sender_device=pc"
        target="_blank"
        rel="noreferrer"
      >
        <img className="footerIcon" src={TikTok} alt="TikTok logo" />
      </a>
      <a
        href="https://www.instagram.com/corn_pups/"
        target="_blank"
        rel="noreferrer"
      >
        <img className="footerIcon" src={Instagram} alt="Instagram logo" />
      </a>
      <a
        href="https://www.facebook.com/cornpupswa"
        target="_blank"
        rel="noreferrer"
      >
        <img className="footerIcon" src={Facebook} alt="Facebook logo" />
      </a>
      <a href="mailto:snohowalks@gmail.com" target="_blank" rel="noreferrer">
        <img className="footerIcon" src={Email} alt="Email logo" />
      </a>
      <div>Copyright © 2024 Corn Pups. All Rights Reserved.</div>
    </div>
  );
}
