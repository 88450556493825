import Nav from "../otherJS/Nav.js";
import "../styles/Home.css";
import { Link } from "react-router-dom";
import Carousel from "../otherJS/Carousel.js";
import Footer from "../otherJS/Footer";

function Home() {
  return (
    <div className="Home">
      <header className="App-header">
        <Nav />
      </header>
      <div className="content">
        <Carousel />
        <center>
          <div className="intro-content-body">
            <h1>Welcome, Corn Pup!</h1>
            <p>
              Corn Pups is a pet service business offering pet training, pet
              sitting and pet boarding. <br /> <br className="d-md-none" />
              Corn Pups is based in Snohomish County, Washington and serves the
              greater pet population of Northern Seattle/Snohomish County.
            </p>
            <Link className="btn btn-hero btn-lg" to="/about" role="button">
              Learn More
            </Link>
          </div>
        </center>
        {/*{" "}
        <section>
          <blockquote
            class="tiktok-embed"
            cite="https://www.tiktok.com/embed/7322653954016283947"
            data-video-id="7322653954016283947"
            style={{ maxWidth: "605px", minWidth: "325px" }}
          >
            {" "}
            <section>
              {" "}
              <a
                target="_blank"
                title="@cornpupswa"
                href="https://www.tiktok.com/@cornpupswa?refer=embed"
                rel="noreferrer"
              >
                @cornpupswa
              </a>{" "}
              Sweet puppy loves make the world go round{" "}
              <a
                title="dogsoftiktok"
                target="_blank"
                href="https://www.tiktok.com/tag/dogsoftiktok?refer=embed"
                rel="noreferrer"
              >
                #DogsofTikTok
              </a>{" "}
              <a
                title="fyp"
                target="_blank"
                href="https://www.tiktok.com/tag/fyp?refer=embed"
                rel="noreferrer"
              >
                #FYP
              </a>{" "}
              <a
                title="mentalhealthawareness"
                target="_blank"
                href="https://www.tiktok.com/tag/mentalhealthawareness?refer=embed"
                rel="noreferrer"
              >
                #mentalhealthawareness
              </a>{" "}
              <a
                title="dogphotography"
                target="_blank"
                href="https://www.tiktok.com/tag/dogphotography?refer=embed"
                rel="noreferrer"
              >
                #DogPhotography
              </a>{" "}
              <a
                title="eskipoo"
                target="_blank"
                href="https://www.tiktok.com/tag/eskipoo?refer=embed"
                rel="noreferrer"
              >
                #eskipoo
              </a>{" "}
              <a
                title="puppy"
                target="_blank"
                href="https://www.tiktok.com/tag/puppy?refer=embed"
                rel="noreferrer"
              >
                #Puppy
              </a>{" "}
              <a
                title="dogoftheday"
                target="_blank"
                href="https://www.tiktok.com/tag/dogoftheday?refer=embed"
                rel="noreferrer"
              >
                #DogOfTheDay
              </a>{" "}
              <a
                title="doglover"
                target="_blank"
                href="https://www.tiktok.com/tag/doglover?refer=embed"
                rel="noreferrer"
              >
                #DogLover
              </a>{" "}
              <a
                title="doglife"
                target="_blank"
                href="https://www.tiktok.com/tag/doglife?refer=embed"
                rel="noreferrer"
              >
                #DogLife
              </a>{" "}
              <a
                target="_blank"
                title="♬ Aesthetic - Tollan Kim"
                href="https://www.tiktok.com/music/Aesthetic-7072513628145977346?refer=embed"
                rel="noreferrer"
              >
                ♬ Aesthetic - Tollan Kim
              </a>{" "}
            </section>{" "}
          </blockquote>{" "}
          <script async src="https://www.tiktok.com/embed.js"></script>
        </section>{" "}
        */}
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Home;
