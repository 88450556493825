import React from "react";
import "../styles/Services.css";
import { Link } from "react-router-dom";
import Nav from "../otherJS/Nav.js";
import Logo from "../assets/temp_logo2.PNG";
import Flowers from "../assets/Flowers.png";
import Kitten from "../assets/Kitten.JPG";
import Bufo from "../assets/Bufo.png";
import Footer from "../otherJS/Footer";

export default function Services() {
  return (
    <div className="Services">
      <header className="Home-header">
        <Nav />
      </header>
      <div className="content">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <h1>Services</h1>
            <div className="row">
              <p className="pt-3 add-services">
                Corn Pups provides walks, pet sitting, and boarding. <br />
                <small>
                  {" "}
                  There is a $5 per day discount for 3+ consecutive days.
                  Additional services considered on a case by case basis.
                </small>
              </p>
            </div>
            <div class="card mb-3" style={{ maxWidth: "100%" }}>
              <div class="row g-0">
                <div class="col-md-4">
                  <img
                    src={Flowers}
                    class="img-fluid rounded-start service-img"
                    alt="A tan dog sitting amongst bright flowers"
                  ></img>
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h3 class="card-title">Walks</h3>
                    <p class="card-text service-descriptions">
                      I offer a personalized pet walking service that allows me
                      to connect with busy pet owners who want the best for
                      their furry (or not-so-furry!) companions. With my
                      experience and passion for animals, I provide tailored
                      exercise routines that suit each pet's individual needs,
                      ensuring they stay healthy and engaged. Whether it's a
                      leisurely stroll, an energetic jog, or an adventurous
                      hike, I make sure pets get the exercise and attention they
                      deserve. Your pet's safety and enjoyment are my top
                      priorities, and I'm here to create a strong bond that lets
                      you entrust your beloved companion to my capable hands.
                    </p>
                    <hr></hr>
                    <h5>Rates</h5>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">$30/half hour</li>
                      <li class="list-group-item">$40/full hour</li>
                      <li class="list-group-item">$20/potty breaks</li>
                      <li class="list-group-item">+$10/additional pet</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-3" style={{ maxWidth: "100%" }}>
              <div class="row g-0">
                <div class="col-md-4">
                  <img
                    src={Kitten}
                    class="img-fluid rounded-start service-img"
                    alt="A white cat with black spots laying in a sunbeam"
                  ></img>
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h3 class="card-title">Sitting</h3>
                    <p class="card-text service-descriptions">
                      I take immense pride in offering a crucial pet sitting
                      service that extends a compassionate hand to both pets and
                      their owners. As a dedicated caregiver, I step in to
                      provide essential care and companionship when you're away.
                      Whether it's a short weekend trip or an extended vacation,
                      I ensure that your pets remain in the comfort of their
                      familiar environment, minimizing any stress or anxiety.
                      Beyond the practical tasks of feeding and walking, I
                      engage in interactive play, offer affectionate
                      companionship, and adhere to any specific routines or
                      requirements your pet might have. With my attentive
                      presence, you can travel or manage your schedule with
                      confidence, knowing that your beloved companions are
                      receiving top-quality care.
                    </p>
                    <hr></hr>
                    <h5>Rates</h5>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        $65/day
                        <br />
                        <small>
                          Please contact for discounted rates on longer stays
                        </small>
                      </li>
                      <li class="list-group-item">
                        $5 medicine administration
                      </li>
                      <li class="list-group-item">+$10/additional pet</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-3" style={{ maxWidth: "100%" }}>
              <div class="row g-0">
                <div class="col-md-4">
                  <img
                    src={Bufo}
                    class="img-fluid rounded-start service-img"
                    alt="A beautiful frog"
                  ></img>
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h3 class="card-title">Boarding</h3>
                    <p class="card-text service-descriptions">
                      When you're looking for a dependable pet boarding
                      solution, I'm here to provide a warm and nurturing
                      environment for your cherished pet. My cozy boarding
                      facilities offer a true home away from home, giving your
                      pet a comfortable place to relax and play. As a dedicated
                      animal enthusiast, I'm committed to meeting your pet's
                      every need, from meals to playtime. With spacious
                      accommodations and personalized care, my pet boarding
                      service goes beyond the basics, offering your furry friend
                      a vacation of their own. While you're away, you can have
                      peace of mind knowing that your pet is receiving the love
                      and attention they deserve from a professional who treats
                      them like family.
                    </p>
                    <hr></hr>
                    <h5>Rates</h5>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        $55/day
                        <br />
                        <small>
                          Please contact for discounted rates on longer stays
                        </small>
                      </li>
                      <li class="list-group-item">
                        $5 medicine administration
                      </li>
                      <li class="list-group-item">+$10/additional pet</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
        <section className="book">
          <div className="shape">
            <div className="inner-shape">
              <img className="logo" src={Logo} alt="Corn Pups logo"></img>
              <h2>Book a Session</h2>
              <Link
                className="btn btn-hero btn-lg d-none d-md-inline-block"
                to="/contact"
                role="button"
              >
                Contact
              </Link>
            </div>
          </div>
        </section>
      </div>
      <footer className="footer-container">
        <Footer />
      </footer>
    </div>
  );
}
