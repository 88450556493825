import React from "react";
import "../styles/Contact.css";
import ContactForm from "../otherJS/ContactForm";
import Nav from "../otherJS/Nav.js";
import Footer from "../otherJS/Footer";

export default function Contact2() {
  return (
    <div className="Contact">
      <header className="Home-header">
        <Nav />
      </header>
      <div className="content">
        <h1>Become a Corn Pup!</h1>
        <p>
          Corn Pups is excited to meet your furry (or not-so-furry) pets for all
          your walking, sitting, and boarding needs
        </p>
        <div className="container">
          <ContactForm />
        </div>
      </div>
      <footer className="footer-container">
        <Footer />
      </footer>
    </div>
  );
}
