import React from "react";
import "../styles/Testimonials.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Nav from "../otherJS/Nav.js";
import Waffle from "../assets/Waffle.png";
import Rookie2 from "../assets/Rookie2.png";
import Charlie from "../assets/Charlie.png";
import Flowers from "../assets/Flowers.png";
import Karma2 from "../assets/Karma2.png";
import JoeM from "../assets/Joe-M.png";
import Winston from "../assets/Winston.jpeg";
import Coco from "../assets/Coco.jpeg";
import Footer from "../otherJS/Footer";

export default function Testimonials() {
  let data = [
    {
      id: 1,
      names: "Jacynth K.",
      title: "Dog parent to Karma",
      review:
        "Lyric has been such a gift to us! Since the second week home with our puppy and all 31 weeks since he has been so flexible and accommodating with our schedules. He is an incredibly reliable walker and a constant positive figure in our puppy's life - we are so fortunate that he was recommended to us by a coworker! Oh AND the best is when he occasionally updates us with cute pics!",
      imageSrc: Karma2,
    },
    {
      id: 2,
      names: "Deborah H.",
      title: "Dog mom to Rookie",
      review:
        "Lyric's remarkable, patient, easy, thoughtful rapport with our sweet Rookie has helped bring our pandemic pupper back from his stint as a wary and protective worrier to his more natural state as a friendly, responsive family dog. He tailors his approach to Rookie's needs that week or that day and lets us know how we can build on his progress. It's evident how much fun he has with his passel of canine buddies. Rookie adores and trusts him, and so do we. ",
      imageSrc: Rookie2,
    },
    {
      id: 3,
      names: "Mya W.",
      title: "Dog parent to Winston",
      review:
        "We have been using Lyric to walk our dog Winston for over 2 years now about twice a week. He is wonderful! Always so patient with our pup who can be a little difficult depending on weather and/or his mood. Winston loves Lyric, and knows when he is coming and is always excited. After his walk he’s always happy and ready for a nap. Definitely recommend Lyric and his services. He’s a part of Winston’s pack now. Thank you!!!",
      imageSrc: Winston,
    },
    {
      id: 4,
      names: "Rhonda G.",
      title: "Dog mom to Rolls",
      review:
        "My dog is one of Lyric's biggest fans. Arlo knows Lyric by name and would get excited for every walk or hike with him. Lyric moved and Arlo hadn't seen him in two years. Arlo finally got to see him again and nearly knocked him over with excitement!",
      imageSrc: Flowers,
    },
    {
      id: 5,
      names: "Molly F.",
      title: "Dog mom to Waffle",
      review:
        "Lyric helped totally transform our rambunctious puppy into a model citizen. Lyric was extremely patient, firm-yet-kind, and focused on using positive reinforcement, all of which are important to me. Thank you, Lyric!",
      imageSrc: Waffle,
    },
    {
      id: 6,
      names: "Jacoby D.",
      title: "Dog dad to Coco",
      review:
        "Lyric has been taking care of my 2 dogs on and off for years. They go crazy to see him everytime he comes over.  After my dog's leg was injured and she became overweight, Lyric was able to get her back into shape. He cares for them and works with people to get what the animals need. Always looking out for the dog's best interest.",
      imageSrc: Coco,
    },
    {
      id: 7,
      names: "Alex H.",
      title: "Dog mom to Charlie",
      review:
        "Charlie is more social and friendly with dogs and people after working with Lyric. He has helped me with training and habit-adjustments, and he has helped her expend her high energy and focus her strong personality with structured play and daily walks. She is happier, calmer, and we are able to move through the world with so much more safety, ease, and enjoyment.",
      imageSrc: Charlie,
    },
    {
      id: 8,
      names: "Joe M.",
      title: "Dog dad to Derby & Milo",
      review: "Lyric is great. Our dogs love him.",
      imageSrc: JoeM,
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="Testimonials">
      <header className="Home-header">
        <Nav />
      </header>
      <div className="content">
        <h1>WHAT CORN PUP PARENTS ARE SAYING</h1>
        <Carousel
          responsive={responsive}
          autoPlay={true}
          infinite={true}
          autoPlaySpeed={10000}
        >
          {data.map((testimonial) => (
            <div className="testimonial" key={testimonial.id}>
              <img src={testimonial.imageSrc} alt={testimonial.names}></img>
              <div className="personal-info">
                <h5 className="names">{testimonial.names}</h5>
                <h6 className="title">{testimonial.title}</h6>
              </div>
              <div className="review-block">
                <p className="review">{testimonial.review}</p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <footer className="footer-container">
        <Footer />
      </footer>
    </div>
  );
}
